<template lang="pug">
  TableV2(
    :isViewAdd="false"
    :headers="headers"
    :items="items.results"
    :pageCount="items.count"
    :currentPage="items.current"
    :querySearch="getDataReport"
    :isLoading="isLoading"
    labelKeyAdd="addingListETI"
    :actions="actions"
    :itemRowBackground="itemRowBackground")
      CertificateAdd(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CertificateAdd from './BackOfficeETIListAdd/BackOfficeETIListAdd.vue'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  data () {
    return {
      headers: [
        { value: 'id',
          text: 'ID',
          sortable: false
        },
        { value: 'contract_number',
          text: this.$t('contractNumber'),
          sortable: false
        },
        { value: 'etiInstitutionName',
          text: this.$t('denomination'),
          sortable: false
        },
        { value: 'director_name',
          text: this.$t('directorName'),
          sortable: false
        },
        { value: 'okpo',
          text: this.$t('edrpou'),
          sortable: false
        },
        { value: 'institutionTypesEti',
          text: this.$t('type'),
          sortable: false
        },
        { value: 'is_disable',
          text: this.$t('status'),
          sortable: false
        },
        { value: 'event',
          text: this.$t('actions'),
          sortable: false,
          class: 'mw-0'
        }
      ],
      isLoading: false,
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.resolve',
          action: item => this.acceptStatement(item),
          color: 'green',
          name: 'mdi-text-box-check',
          checkAccess: item => item.institution_type === 1 && item.can_create_standart_statement
        },
        {
          id: 2,
          tooltip: 'tooltip.ban',
          action: item => this.acceptStatement(item),
          color: 'red',
          name: 'mdi-text-box-remove',
          checkAccess: item => item.institution_type === 1 && !item.can_create_standart_statement
        },
        {
          id: 3,
          tooltip: 'tooltip.go',
          action: (item) => this.goToEti(item),
          color: 'blue',
          name: 'mdi-arrow-right'
        }
      ]
    }
  },
  components: { CertificateAdd },
  computed: {
    ...mapState({
      items: state => state.sailor.backOfficeETIList,
      institutionTypes: state => state.directory.institutionTypesEti
    })
  },
  methods: {
    ...mapActions(['getETICertificationInstitutions', 'patchETICertificationInstitutionById']),
    async getDataReport (filter) {
      this.isLoading = true
      await this.getETICertificationInstitutions(filter)
      this.isLoading = false
    },
    acceptStatement (item) {
      this.$swal({
        icon: 'warning',
        title: item.can_create_standart_statement ? this.$t('banStatement') : this.$t('resolveStatemnt'),
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async (response) => {
        if (response) {
          this.isLoading = true
          const result = await this.patchETICertificationInstitutionById({ id: item.id, body: { can_create_standart_statement: !item.can_create_standart_statement } })
          if (SUCCESS_CODE.includes(result.code)) {
            this.$notification.success(result.data.can_create_standart_statement ? this.$t('successResolve') : this.$t('successBan'))
          }
          this.isLoading = false
        }
      })
    },
    goToEti (item) {
      item && this.$router.push({ path: `/back-office/eti/list/${item.id}` })
    },
    itemRowBackground (item) {
      return item.is_red ? 'red-table-row' : ''
    }
  }
}
</script>
